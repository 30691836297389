import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import storage from "../../../Storage";
import {encode, isMobile} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    ask() {
        Swal.fire({
            title: 'Logout Now?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            confirmButtonText: 'Yes!',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                socket.emit(C.LOGOUT_USER, encode({token: storage.getKey('token')}));
                localStorage.clear();
                Cookies.remove("session");
                Cookies.remove("auth");
                Cookies.remove("uid");
                window.location.replace('./');
            }
        });
    }

    render() {
        return (
            <>
                <NavLink to="#" className={"nav-link text-danger text-right"} onClick={this.ask}>Logout&nbsp;<i className={"fa fa-power-off"}></i></NavLink>
            </>
        );
    }
}

export default Logout;