import React from 'react'
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {SITE, BRAND, REFERRAL_PERCENTAGE, BORDER_RADIUS, formatAmount} from "../../../Helper";
import {Card, Divider} from "@mui/material";
import Diversity1Icon from '@mui/icons-material/Diversity1';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import formatCurrency from "format-currency";
import Withdraw from "./Withdraw";
import List from "./List";
import Earnings from "./Earnings";

export default class Refer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            user_id: storage.getKey('user_id') ? storage.getKey('user_id') : null,
            logged: false,
            earnings: 0,
            referral_count: 0,
            copySuccess: ''
        };
    }

    componentWillMount() {
        if (this.state.token !== null) {
            this.setState({logged: true});
        }
        socket.on(C.MY_REFERRAL, data => this.setReferralDetails(data));
    }

    setReferralDetails = (data) => {
        this.setState({earnings: data.earnings, referral_count: data.referral_count});
    }

    copyToClipboard = (e) => {
        navigator.clipboard.writeText(SITE + '/win/' + this.state.user_id).then(r => {
            this.setState({copySuccess: 'Link copied to clipboard!'});
        });
    };

    render() {
        let {logged, earnings, referral_count} = this.state;

        return (
            <>
                <div className={'row'}>
                    <div className={'col-12 text-center'}>
                        <Card style={{color: '#f5f5f5cc', minHeight: '300px', maxWidth: '800px', margin: 'auto'}} className={"p-1 mb-0 custom-panel-light"}>
                            <div className={'row'}>
                                <div className={'col-8'}>
                                    <h4 style={{color: 'gold'}}>{BRAND} - Refer and Earn</h4>
                                    <h6 style={{color: '#f5f5f5cc'}}>Invite Friends to get <strong className={'text-warning'}>{REFERRAL_PERCENTAGE}%</strong> on every deposit
                                        your member make.
                                    </h6>
                                </div>
                                <div className={'col-4 mt-2'}>
                                    <Diversity1Icon className={'text-warning'} style={{width: '75px', height: 'auto'}}/>
                                </div>
                            </div>

                            {logged ?
                                <>
                                    <Divider style={{color: 'gold'}}>Referral Link</Divider>

                                    <p>Copy & Share Your Referral Link below</p>

                                    <div onClick={this.copyToClipboard} className={"mb-2 text-warning"} style={{
                                        height: '37px',
                                        borderRadius: BORDER_RADIUS,
                                        backgroundColor: 'green',
                                        padding: '0px 16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}>
                                        <span style={{flex: '0 1 90%', whiteSpace: 'nowrap'}}>{SITE + '/win/' + this.state.user_id}</span>
                                        <i style={{fontSize: '1.5em'}} className={"fa fa-copy"}/>
                                    </div>
                                    <span className={"text-success"}>{this.state.copySuccess}</span>

                                    <Divider style={{color: 'gold'}}>Referral Account</Divider>

                                    <div style={{margin: '0.1rem'}} className="row text-center">
                                        <div className={'col-3 p-0'}>
                                            <div className={"my-gradient mr-1 border-right"}>
                                                <span className={'text-warning'}>Total Referrals</span><br/>
                                                <span className="text-white font-15">{formatAmount(referral_count, 0)}</span>
                                            </div>
                                        </div>
                                        <div className={'col-3 p-0'}>
                                            <div className={'my-gradient mr-1 border-right'}>
                                                <span className={'text-warning'}>Total Earnings</span><br/>
                                                <span className="text-white font-15">KES {formatCurrency(parseFloat(earnings).toFixed(2))}</span><br/>
                                            </div>
                                        </div>
                                        <div className={'col-6 p-0'}>
                                            <div className={'my-gradient mr-1 border-right'}>
                                                <p style={{fontSize: '0.8em'}} className={"text-warning m-0"}>Withdraw to Wallet or M-PESA:</p>
                                                <Withdraw earnings={earnings}/>
                                            </div>
                                        </div>
                                    </div>

                                    <Divider style={{color: 'gold'}}>Referral Statistics</Divider>

                                    <Tabs>
                                        <TabList>
                                            <Tab><span className={'text-white'}>Latest Referrals</span></Tab>
                                            <Tab><span className={'text-white'}>Latest Earnings</span></Tab>
                                        </TabList>
                                        <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                            <List/>
                                        </TabPanel>
                                        <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                            <Earnings/>
                                        </TabPanel>
                                    </Tabs>
                                </>
                                :
                                <span className={'text-warning'}>Please login to see your link.</span>
                            }
                        </Card>
                    </div>
                </div>
            </>
        );
    }
}