import React from 'react'
import {__, isMobile, wait, Event, crashColor, formatAmount, BORDER_RADIUS} from "../../../Helper";
import C from "../../../Constant";
import storage from "../../../Storage";
import Engine from "./Engine";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gameRow: [],
            show: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow() {
        this.setState({show: true, effect: 'pulse'});
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    render() {
        return <Parent tab={true} clicked={this.handleClose}/>;
    }
}

class Parent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            game_id: '',
            numbers: '',
            hash: '',
            md5: ''
        };
    }

    onChange(event, players, hash, md5, game_id, numbers) {
        this.setState({
            md5: md5,
            hash: hash,
            numbers: numbers,
            game_id: game_id,
            players: players,
            color: crashColor(numbers)
        });
    }

    render() {
        const {players, hash, md5, game_id, busted} = this.state;
        return <Child tab={this.props.tab} clicked={this.props.clicked.bind(this)} onChange={this.onChange.bind(this, players, hash, md5, game_id, busted)}/>
    }
}

class Child extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: Engine,
            loading: true,
            padding: 'p-0',
            token: storage.getKey('token') !== null ? storage.getKey('token') : null,
            historyRow: [],
            players: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let {engine} = this.state;
        engine.getHistory();
        engine.trigger.on(C.HISTORY_CRASH, (data) => this.gameSync(data));

        if (isMobile()) {
            this.setState({padding: 'p-0'})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    gameSync(list) {
        if (this._isMounted) {
            wait(300).then(() => {
                this.setState({loading: false});
            })

            let data = list.history;
            data = __.xor(data);
            data = __.reverse(data);

            this.setState({historyRow: []});

            data.forEach((array, i) => {
                if (!__.isUndefined(array)) {
                    let row = <Block key={__.toString(array.gid)} tab={true} clicked={this.props.clicked} array={array}/>;
                    this.setState(state => ({historyRow: [row, ...state.historyRow]}));
                }
            });
        }
    }

    render() {
        return (
            <Paper className={'custom-panel-dark'} sx={{width: '100%', overflow: 'hidden'}}>
                {this.state.loading ?
                    <div className="text-center">
                        <div className="spinner-grow text-danger my-1" role="status"/>
                    </div>
                    :
                    <TableContainer sx={{maxHeight: 511}}>
                        <Table stickyHeader size="small" aria-label="player list">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>Game Reference</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>Result[x]</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} align="center">Game Hash</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.historyRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Paper>
        );
    }
}

class Block extends React.Component {
    verifyHash(hash) {
        Event.emit('game_verify', hash)
    }

    render() {
        let {clicked, tab} = this.props;
        let {gid, busted, hash} = this.props.array;

        busted = __.toNumber(busted).toFixed(2);

        let color = crashColor(busted);

        return (
            <TableRow className={'custom-panel-dark'} key={gid}>
                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} scope="row">{gid}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} align="left">
                    <div style={{borderRadius: BORDER_RADIUS, backgroundColor: '#2C2D30', width: '50px', padding: '1px'}} className={'text-center text-' + color}>
                        {busted}
                    </div>
                </TableCell>
                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} scope="row">
                    <input style={{fontSize: '.7rem'}} type={"text"} className={"pt-0 pb-0 form-control text-center h-100 no-radius cpt"} value={hash.substr(0, 60) + '...'}
                           readOnly={true}/>
                </TableCell>
            </TableRow>
        );
    }
}

export default History;