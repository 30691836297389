import React from 'react'
import storage from "../../../Storage";
import {wait} from "../../../Helper";

export default class Bonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token')
        };
    }

    componentWillMount() {
        if (this.state.token === null) {
            if (this.props.params) {
                let path = this.props.params.pathname;
                let tk = path.replace('/win/', '');
                if (tk) {
                    storage.setKey('referrer', tk);
                    wait(500).then(() => {
                        this.props.navigate('/signup', { replace: true });
                    });
                }
            }
        } else {
            alert('You are already logged in!')
            wait(500).then(() => {
                //this.props.navigate('/');
                window.location.replace('/')
            });
        }
    }

    render() {
        return (
            <>
            </>
        );
    }
}