import React, {Component} from "react";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {CONTACT_NUMBER, decode, encode, sendNotification, wait} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

class Forgot extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            disabled: false,
            phone: '',
            status: false,
            status_state: 'warning'
        };
    }

    componentDidMount() {
        socket.on(C.RESET_PASSWORD, data => this.resetPassword(decode(data)));
    }

    resetPassword = (data) => {
        let response = data;
        const {t} = this.props;

        this.setState({disabled: false});

        if (response.status) {
            this.setState({status: "The Password has been sent to your registered mobile.", status_state: 'success'});
        } else {
            this.setState({status: "Account not found! Help, call " + CONTACT_NUMBER, status_state: 'danger'});
        }
    };

    submitForm(e) {
        e.preventDefault();

        this.setState({disabled: true, status: "Checking account...", status_state: 'warning'});

        wait(700).then(() => {
            socket.emit(C.RESET_PASSWORD, encode({phone: this.state.phone}));
        })
    }

    render() {
        return (
            <>
                <form style={{display: 'contents'}} onSubmit={(e) => this.submitForm(e)}>
                    <div className={'custom-card m-2 w-100'}>
                        <span className={'text-white custom-label'}>Mobile&nbsp;&nbsp;</span>
                        <input placeholder={'-- e.g. 0722000000 --'} type={"number"} id={"mobile-number"} className={"text-strong form-control input-number custom-input"}
                               name={"mobile"} onChange={e => this.setState({phone: e.target.value})} autoComplete={'off'}/>
                        <PhoneAndroidIcon className={'text-white'}/>
                    </div>

                    <Button size={'large'} variant="contained" className={'custom-button m-2'} type={'submit'} fullWidth sx={{m: 2}}
                            disabled={this.state.disabled}><MoveToInboxIcon/>&nbsp;Retrieve
                        Password</Button>

                </form>
                {this.state.status &&
                    <span style={{fontSize: '.7rem'}} className={"text-" + this.state.status_state + " w-100 text-center"}>{this.state.status}</span>
                }
                <div style={{borderTop: '1px solid #80808063'}} className={'col-12 text-center mb-3 mt-3'}>
                    <table width={'100%'}>
                        <tr>
                            <td><Link className={'text-white'} style={{textDecoration: 'none', fontSize: '12px'}} to={'/signup'}>New? <span
                                style={{fontWeight: 'bold'}}>Register</span></Link></td>
                            <td><Link className={'text-white'} style={{textDecoration: 'none', fontWeight: 'bold', fontSize: '12px'}} to={'/login'}>Login Instead</Link></td>
                        </tr>
                    </table>
                </div>
            </>
        );
    }
}

export default Forgot;