import React from "react";
import {Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import {defaultAvatar} from "../../../../Helper";

class User extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            level: 1,
            name: storage.getKey('name'),
            phone: storage.getKey('phone'),
            avatar: storage.getKey('avatar') !== 'false' ? storage.getKey('avatar'): defaultAvatar
        };
    }
    render() {
        return (
                    <Dropdown left="true">
                        <Dropdown.Toggle split variant="b btn-sm" id="dropdown-split-user">
                            <i className={"fa fa-list text-info"}></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={"droper user-dropdown-detail"}>
                            <div className="user-bar">
                                <span className="text-white">{this.state.name}</span>
                            </div>

                            <div className="dropdown-divider"/>
                            <div className="user-links">
                                <Dropdown.Item as={'button'} className={"animated fadeIn"}>

                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
        );
    }
}

export default User;