import React from "react";
import ManualBet from './includes/ManualBet';
import {isMobile, getUID} from "../../../Helper";

class Bet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
            uid: getUID
        };
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({padding: 'p-1', margin: 'mt-1'})
        }
    }

    render() {
        let {token, isLogged, mobile} = this.props;

        return (
            <ManualBet token={token} isLogged={isLogged} mobile={mobile}/>
        );
    }
}

export default Bet;