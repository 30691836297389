import React from 'react'
import {Table} from "react-bootstrap";
import storage from "../../../Storage";
import {encode, wait, fixDate} from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: []
        };
    }

    componentDidMount() {
        wait(1700).then(() => {
            this.setState({loading: false});
        })
        socket.emit(C.MY_AFF, encode({token: storage.getKey('token')}));
        socket.on(C.MY_AFF, data => this.makeList(data));
    }

    makeList = (data) => {
        this.setState({loading: false, list: data});
    }

    render() {

        const list = this.state.list.map((row, index) =>
            <List key={index} row={row}/>
        );

        return (
            <div className="table-responsive">
                {
                    this.state.loading ?
                        <div className="text-center">
                            <div class="spinner-border text-info my-3" role="status"/>
                        </div>
                        :
                        <Paper sx={{width: '100%', overflow: 'hidden'}}>
                            <TableContainer sx={{maxHeight: 211}}>
                                <Table stickyHeader size="small" aria-label="player list">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>REF</TableCell>
                                            <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>Username</TableCell>
                                            <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>Date Registered</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.length === 0 ? <span>-- No Referrals Yet --</span> : list}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                }
            </div>
        );
    }
}

class List extends React.Component {
    render() {
        const {key, row} = this.props
        return (
            <TableRow key={key} sx={{backgroundColor: '#000000eb'}}>
                <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} component="th" scope="row">{row.id}</TableCell>
                <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} component="th" scope="row">{row.username}</TableCell>
                <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} component="th" scope="row">{fixDate(row.created)}</TableCell>
            </TableRow>
        );
    }
}