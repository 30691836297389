import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from 'react-redux';
import store from './store';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Static/css/animate.css";
import "./Static/css/icons.css";
import 'react-notifications-component/dist/theme.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "./Static/css/pc.css";

import './i18n';
import {BrowserRouter as Router} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";

//MUI THEME
const THEME = createTheme({
   typography: {
    "fontFamily": `"Oswald",Helvetica,Arial,sans-serif`,
    "fontSize": 12,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={THEME}>
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
    </ThemeProvider>
);

serviceWorker.unregister();