import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {Event, BRAND, CONTACT_NUMBER} from "../../../../Helper";
import {Divider} from "@mui/material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import LaunchIcon from '@mui/icons-material/Launch';
import YouTube from "react-youtube";

class HowToPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse',
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('show_help', this.handleShow);
    }

    handleShow(e) {
        this.setState({show: true, effect: 'pulse'});
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    render() {
        let {show, effect} = this.state;

        return (
            <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} backdrop="static" show={show} onHide={this.handleClose}
                   aria-labelledby="promo-md-modal"
                   className={'animated ' + effect}>
                <Modal.Header className={'p-2'} closeButton>
                    <Modal.Title style={{color: 'antiquewhite', fontSize: '1.2rem'}}>
                        {BRAND} - How to Play
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontSize: '0.81rem'}} className={'p-2'}>
                    <HelpFieldSet/>
                </Modal.Body>
                <Modal.Footer className={'p-2'}>
                    For more, call: {CONTACT_NUMBER}
                </Modal.Footer>
            </Modal>
        );
    }
}

class HelpFieldSet extends React.Component {

    render() {
        return (
            <>
                <ol style={{paddingLeft: '1rem'}}>
                    <li>Enter your <strong>Bet Amount</strong>, e.g. <strong>10.00</strong>, then click <strong>BET</strong>. You can optionally enter an <strong>Auto Cashout (x)</strong> value to let the
                        system cash out for you automatically.
                    </li>
                    <li>Once the Game starts running, click on <strong>Cashout @ x</strong> to cash out manually.</li>
                    <li>If the Game busts before you cash out, you lose.</li>
                    <li>Note that you can activate the Auto Cashout option to let the System cash out for you automatically.</li>
                    <li>You can also turn on the Auto Bet option to let them System BET for you automatically. With this option, you won't have to click the BET button for every Game.</li>
                </ol>

                <Divider textAlign={"left"}>Definition of Terms</Divider>

                <table className={"table table-striped mb-0 text-white"}>
                    <tr>
                        <td><strong className={"text-warning text-nowrap"}>Amount:</strong></td>
                        <td>The amount you want to Bet with e.g. <span className="text-warning">10.00</span></td>
                    </tr>
                    <tr style={{borderTop: '1px solid #f5f5f552'}}>
                        <td><strong className={"text-warning text-nowrap"}>Auto Cashout:</strong></td>
                        <td>If activated, the system will cash out for you automatically when the graph reaches the Multiplier entered. By default, it is deactivated so that you
                            can cash out manually.
                        </td>
                    </tr>
                    <tr style={{borderTop: '1px solid #f5f5f552'}}>
                        <td><strong className={"text-warning text-nowrap"}>Auto Bet:</strong></td>
                        <td>If activated, the system will place the next Bet for you automatically. You won't need to click the BET button for every game.</td>
                    </tr>
                    <tr style={{borderTop: '1px solid #f5f5f552'}}>
                        <td><strong className={"text-warning text-nowrap"}>Multiplier:</strong></td>
                        <td>e.g. <strong className={'text-warning'}>1.10</strong> - This is the Odd on the graph. Your amount is multiplied by this number if you cash
                            out before the Game busts.
                        </td>
                    </tr>
                </table>
            </>
        );
    }
}

class YouTubeHelp extends React.Component {
  render() {
    const opts = {
      //height: '195',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    return <YouTube videoId="nRNdbIKf0yI" opts={opts} onReady={this._onReady} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default HowToPlay;