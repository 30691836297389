import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Cookies from "js-cookie";
import UserHeader from './Parts/Header/Header-User';
import GuestHeader from './Parts/Header/Header-Guest';
import socket from "../../Socket";
import {Event, decode, encode, wait, sendNotification} from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import Login from "./Auth/Login";
import storage from "../../Storage";
import Footer from "./Parts/Footer";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: <GuestHeader t={this.props.t} location={this.props.location}/>,
            auth: false,
            name: storage.getKey('name'),
            phone: storage.getKey('phone'),
        }
    }

    componentDidMount() {
        socket.on(C.ONLINE, (status) => this.loginUser(decode(status)));
        socket.on('RE-LOGIN', (data) => this.reLoginUser(decode(data)));
        Event.on('showAuthModal', (status) => this.activeAuth(status));

        /**
         * Initialize Authentication
         */
        const sessionCookie = Cookies.get("auth");
        const here_token = storage.getKey('token');

        if (here_token !== null && sessionCookie !== undefined) { //when a user already logged in
            socket.emit(C.ONLINE, encode({
                token: here_token,
                identifier: 'from index page',
            }));
        } else {
            wait(300).then(() => {
                localStorage.clear();
            });
        }
    }

    activeAuth = (status) => {
        this.setState({auth: status});
    }

    reLoginUser = (data) => {
        wait(300).then(() => {
            sendNotification("Please log in again!", 'error', 'top-center');
            localStorage.clear();
        });
    }

    loginUser = (data) => {
        wait(500).then(() => {
            if (data.status === true) {

                Cookies.set("uid", data.id, {expires: 14});
                Cookies.set("auth", true, {expires: 14});

                storage.setKey('name', data.name);
                storage.setKey('email', data.email);
                storage.setKey('phone', data.phone);
                storage.setKey('avatar', data.avatar);
                storage.setKey('friends', data.friends);
                storage.setKey('room', data.room);
                storage.setKey('user_id', data.user_id);

                this.setState({header: <UserHeader t={this.props.t} location={this.props.location}/>});

                if (data.identifier === 'from login page') {
                    //window.location.replace('/')
                    this.props.navigate('/', { replace: true });
                }

            } else {
                wait(700).then(() => {
                    localStorage.clear();
                })
            }
        })
    }

    render() {
        let {header, auth} = this.state;
        let {content} = this.props; // Pages / Games Contents
        let wallet; // Show Wallet if User don't Have Cash

        try {
            wallet = this.props.get_wallet.show;
        } catch (e) {
        }

        const {t} = this.props;
        return (
            <>
                {wallet &&
                    <WalletAlert t={t} uid={this.props.get_wallet.uid}/>
                }
                {auth === true &&
                    <Login t={t} justModal="true"/>
                }
                <div id="content" className="p-1 p-md-4 bg-body-pc animated fadeIn">
                    {header}

                    {content}

                    <Footer t={t}/>
                </div>
            </>
        );
    }
}

Index.propTypes = {
    get_wallet: PropTypes.string
};

const mapStateToProps = state => ({
    get_wallet: state.items.get_wallet
});

export default connect(mapStateToProps, null)(Index);