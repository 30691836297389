import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import Scroll from "react-scroll";
import {setName} from "../../../../actions/gameChat";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import UserModal from "../../../Components/User/Stat/Modal";
import {getElement, __, wait, defaultAvatar, Event, sendNotification, getUID, encode} from "../../../../Helper";
import C from "../../../../Constant";

const Element = Scroll.Element;
const SC = Scroll.scroller;

class Messages extends React.Component {
    bubble = 'none';
    bubbleMsg = 0;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            alertSpam: false,
            messages: [],
            loader: [],
            clientCountry: storage.getKey('country') ? storage.getKey('country') : "GLOBAL",
            uid: getUID
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.setLoader();

        wait(500).then(() => {
            let c = this.state.clientCountry;
            socket.emit(C.CHATS, encode({country:c, id: this.state.uid}));
        });

        socket.on(C.CHATS, data => this.getChats((data)));
        socket.on(C.ADD_CHAT, data => this.getNewChats((data)));
        // Event.on('twitch', data => this.getNewChats(data));

        Event.on('scrolldone', () => this.updateScroll());
        this.updateScroll();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this._isMounted) {
            if (nextProps.country) {
                this.setState({clientCountry: nextProps.country, messages: []});
                this.setLoader();

                wait(500).then(() => {
                    let c = nextProps.country;

                    if (__.lowerCase(c) === "spam") {
                        this.setState({alertSpam: true});
                        Event.emit('open_socket');
                    } else {
                        this.setState({alertSpam: false});
                        Event.emit('close_socket');
                    }

                    socket.emit(C.CHATS, encode({country:c, id: this.state.uid}));
                });
            }
            this.updateScroll();
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this._isMounted) {
            let e = getElement('#messages'),
                t = e.clientHeight,
                n = e.scrollHeight,
                r = e.scrollTop;
            this.scrollBottom = n - r - t
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this._isMounted) {
            this.updateScroll();
            if (this.state.messages.length >= 150) {
                let slice = __.slice(this.state.messages, this.state.messages.length - 1, this.state.messages.length);
                this.setState({messages: slice})
                this.state.messages.length = 150;
            }
        }
    }

    updateScroll() {
        if (this._isMounted) {
            /*if (this.scrollBottom <= 150) {
                SC.scrollTo('chatBarElement', {
                    delay: 3,
                    smooth: true,
                    containerId: 'messages',
                    offset: getElement('#sc').scrollHeight * 2,
                });
                this.bubbleMsg = 0;
            } else {
                this.bubbleMsg += 1;
                if (this.bubbleMsg !== 0) {
                }
            }*/
        }
    }

    setLoader = () => {
        if (this._isMounted) {
            const icon = <div className="ycenter">
                <div className={"spinner-border text-info"} role="status"/>
            </div>;
            this.setState({loader: icon})
        }
    };

    getChats(data) {
        if (this._isMounted) {
            this.setState({messages: [], loader: []});
            sortByTime(data).forEach((message, i) => {
                this.setState(state => ({messages: [<Message t={this.props.t} key={i} message={message} redux={this.props}/>, ...state.messages]}));
            });
        }
    }

    getNewChats(message) {
        if (this._isMounted) {
            let {country} = this.props;
            let currentCountry = country ? country : this.state.clientCountry;

            if (__.upperCase(message.country) === __.upperCase(currentCountry)) {
                let add = this.state.messages.concat([
                    <Message t={this.props.t} key={this.state.messages.length + 1} message={message} redux={this.props}/>
                ]);
                this.setState({messages: add});
            }
        }
    }

    hideBubble = () => {
        if (this._isMounted) {
            SC.scrollTo('chatBarElement', {
                delay: 3,
                smooth: true,
                containerId: 'messages',
                offset: getElement('#sc').scrollHeight * 2,
            });
            this.bubbleMsg = 0;
        }
    }

    render() {
        return (
            <Element name="chatBarElement">
                <div style={{paddingLeft:'7px'}} className="navDan chatsDan" id={'sc'}>
                    {this.state.loader}
                    {this.state.messages}
                </div>
            </Element>
        );
    }
}

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: storage.getKey('name')
        }
    }

    callUser(name) {
        Event.emit('call_user', '@' + name + ' ')
    }

    createMarkup(msg) {
        return {__html: msg};
    }

    isSharing = (value) => {
        if (__.isString(value)) {
            let test = value.substr(0, 5);
            if (test === '{"gam' || test === '{"uid')
                return true;
            else
                return false;
        } else
            return true;
    }

    makeStars = (num) => {
        let stars = 5;
        let userLevel = stars - parseFloat(num);
        let g = [], o = [], f = [];
        for (let i = 0; i < userLevel; i++) {
            g.push(<i className={'mdi mdi-star'}/>);
        }
        for (let i = 0; i < num; i++) {
            o.push(<i className={'mdi mdi-star text-success'}/>);
        }
        f.push(o, g);
        return f;
    }

    hiddenAlert = () => {
        return sendNotification('All users in the spam room are hidden!', 'warning', 'bottom-center');
    }

    render() {
        const {t, key} = this.props;
        let {name, message, avatar, time, level, twitch} = this.props.message;
        const content = message;
        let username = name;
        let style;
        let isNotSelf = false;
        let highlight = '';
        avatar = avatar !== 'false' ? avatar : defaultAvatar;

        //System Message
        if (username === 'SystemBot') {
            style = {
                background: '#673ab7'
            }
        }

        if (username === 'Support') {
            style = {
                background: 'red'
            }
        }

        if (this.state.name === username)
            isNotSelf = true;

        if (!this.isSharing(message)) {
            let r = new RegExp('@' + this.state.name + '(?:$|[^a-z0-9_\-])', 'i');
            if (this.state.name !== username && r.test(message)) {
                // new Audio('/assets/sounds/ding.mp3').play();
                highlight += 'highlight-on';
            }

            //Check Links
            let expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
            let regex = new RegExp(expression);
            if (message.match(regex)) {
                let hidden = t('link_is_hidden');
                message = <div dangerouslySetInnerHTML={this.createMarkup(`<span className="text-danger">[ ${hidden} ]</span>`)}/>;
            }

            let arr = [];

            //Check Usernames Calls
            let check = __.words(message, /@([a-zA-Z0-9]+)/gi);
            if (!twitch) {
                if (check.length !== 0) {
                    for (let i = 0; i < check.length; i++) {
                        let username = check[i];
                        let link = <span key={i} className="text-warning"> @<UserModal key={i} username={__.replace(username, '@', '')} cssClass="text-warning"/></span>;
                        // arr.push(link)
                        try {
                            message = message.split(" ").map((c) => (c === username ? link : " " + c));
                        } catch (e) {
                        }
                    }
                }
            }
        } else {
            style = {
                width: '100%'
            }

            if (__.isString(message)) {
                message = JSON.parse(message)
            }

            let isLost = false;
            let profit = parseFloat(message.profit);

            if (profit === 0 || profit === 0.00000000) {
                isLost = true;
            }

            message = <div className="share-result">
                {isLost ?
                    <h4 className="text-danger text-center mt-0 mb-3">{t('lost')}</h4>
                    :
                    <h4 className="text-warning text-center mt-0 mb-3 trmg">
                        <img src="/assets/images/contest.png" className={"img-fluid"} style={{width: 40}}/> <br/> {t('winner')}
                    </h4>
                }
                <Row>
                    <Col md="6" sm="6">
                        <div className="text-success bg-soft-info rounded py-2">{message.result}x <br/> {t('result')}</div>
                    </Col>
                    <Col md="6" sm="6">
                        <div className="text-white bg-soft-info rounded py-2">
                            <img className="mini-coin-8 mr-1" src={'/assets/images/' + __.lowerCase(message.coin) + '.png'}/>
                            {message.profit} <br/> {t('profit')}
                        </div>
                    </Col>
                    <Col md="12" sm="12">
                        <div className="bg-soft-info text-white rounded mt-3 py-2">ID: {message.gid}</div>
                    </Col>
                </Row>
            </div>
        }

        // message = __.replace(message, /@([a-zA-Z0-9]+)/gi, `<a className={"text-warning"} href="/user/$1">@$1</a>`)

        let my_color = (['CS', 'Support', 'Admin', 'Customer Care', 'Help'].includes(username)) ? '#E41C1D' : '#FDB54B';
        return (
            <p key={__.toString(key)} className={'mb-0 animated XfadeIn ' + highlight}>
                <span style={{color: 'darkgrey'}}>{time} </span>
                <span style={{color: 'whitesmoke'}}>{username}</span>
                <span style={{color: my_color}}>: {message}</span>
            </p>
        );
    }
}

function sortByTime(input) {
    function r(c) {
        return c.sorter ? -c.sorter : null;
    }

    return __.sortBy(input, r);
}

Messages.propTypes = {
    country: PropTypes.string
};

const mapStateToProps = state => ({
    country: state.items.country
});

export default connect(mapStateToProps, {setName})(Messages);