import {Stack} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Component} from "react";
import storage from "../../../../Storage";
import {__, COIN_TOKEN, decode, encode, forceSatoshiFormat, formatAmount, isValidNumber, MAX_WITHDRAW, MIN_WITHDRAW, sendNotification, wait} from "../../../../Helper";
import ShopIcon from '@mui/icons-material/Shop';
import socket from "../../../../Socket";
import C from "../../../../Constant";
import coins from "../../../coins";

class Withdrawal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            credit: storage.getKey('credit'),
            slide: false,
            final: false,
            confirm: false,
            amount: MIN_WITHDRAW,
            im: true,
            error: false,
            error2: false,
            loading: false,
            loader: true,
            coin: COIN_TOKEN,
            fee_withdrawal: 0
        };
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT_COIN, encode({token: this.state.token, coin: this.state.coin}));
        socket.on(C.CREDIT_COIN, data => this.setCreditCoin(decode(data)));
        socket.on(C.SUBMIT_NEW_WITHDRAWL, data => this.addWithdrawal(decode(data)));

        wait(500).then(() => {
            this.setState({loader: false})
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    addWithdrawal(data) {
        if (this._isMounted) {
            wait(1000).then(() => {
                if (!data) {
                    sendNotification('Request failed!', 'danger', 'top-center');
                }
                let message = __.toString(data.status);
                sendNotification(message, 'danger', 'top-center');
                socket.emit(C.CREDIT, encode({token: this.state.token}));
                this.setState({final: false, confirm: false, loading: false});
            })
        }
    }

    handleInputChanges = (event) => {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;

        if (target.name === 'coin') {
            socket.emit(C.CREDIT_COIN, encode({token: this.state.token, coin: value}));
        }

        if (target.name === 'amount') {
            if (!isValidNumber(value)) return false;

            if (value.length > 20) {
                return false;
            }

            let error = false;

            if (Number(value) > Number(this.maxUserCredit()))
                error = true;

            this.setState({error: error})

            //Dan
            if (value > '0') {
                value = Math.floor(value);
            }
        }

        let fee = value <= 1000 ? 16 : 23;
        this.setState({[target.name]: value, fee_withdrawal: fee});
    }

    getUserCredit() {
        return forceSatoshiFormat(this.state.credit);
    }

    setCreditCoin(data) {
        let {credit} = data;
        this.setState({credit: credit, fee_withdrawal: 16});
    }

    maxUserCredit() {
        this.setState({error: false, error2: false});
        return Math.floor(this.getUserCredit());
    };

    submitForm(e) {
        if (this._isMounted) {
            e.preventDefault();

            let {token, error, coin, confirm, amount, credit, fee_withdrawal} = this.state;

            if (!amount)
                return;

            let finalMoney = forceSatoshiFormat(amount - fee_withdrawal);

            if (__.toNumber(finalMoney) <= 0) return console.log('-4');

            let restrecFee = true, minFee;

            //Check for minimum amount
            coins.forEach((item, key) => {
                if (__.lowerCase(item.preffix) === __.lowerCase(coin)) {
                    let min = forceSatoshiFormat(item.min);
                    finalMoney = __.toNumber(finalMoney);
                    min = __.toNumber(min);
                    if (finalMoney < min) {
                        minFee = forceSatoshiFormat(min);
                        restrecFee = false;
                    }
                }
            });

            if (!restrecFee) {
                this.setState({error2: true, minFee: minFee});
                return console.log('-5');
            }

            this.setState({loading: true});

            wait(500).then(() => {
                socket.emit(C.SUBMIT_NEW_WITHDRAWL, encode({
                    token: token,
                    coin: coin,
                    wallet: 'NOT NEED',
                    amount: amount,
                    network: 'BEP20'
                }));
            })
        }
    }

    render() {
        let {amount, fee_withdrawal} = this.state;

        return (
            <form onSubmit={(e) => {
                this.submitForm(e)
            }}>
                <Stack>
                    <div className={'custom-card m-2'}>
                        <span className={'text-white custom-label'}>Amount&nbsp;&nbsp;</span>
                        <input placeholder={'-- e.g. ' + MIN_WITHDRAW + ' --'} type={"number"} id={"targetAmount"} value={this.state.amount} autoComplete={'off'}
                               name={"amount"} min={MIN_WITHDRAW} max={MAX_WITHDRAW}
                               onChange={this.handleInputChanges} required={true} className={"text-strong form-control input-number custom-input"}/>
                        <AttachMoneyIcon className={'text-white'}/>
                    </div>
                    <span style={{fontSize: '.6rem'}}
                          className={'text-white text-center'}>Transfer Fee: {fee_withdrawal} | To Disburse: {forceSatoshiFormat(amount - fee_withdrawal)}</span>
                    <Button size={'large'} variant="contained" className={'custom-button m-2'} type={'submit'} sx={{m: 2}} disabled={this.state.loading}><ShopIcon/>&nbsp;Withdraw
                        Now</Button>

                    <Typography className={'m-2'} sx={{color: '#f5f5f59e'}} gutterBottom>
                        <dt className={'text-warning font-weight-bold'}>Instructions:</dt>
                        Minimum Withdrawal amount: {MIN_WITHDRAW}/-<br/>
                        Maximum Withdrawal amount: {formatAmount(MAX_WITHDRAW)}/-<br/>
                        Daily Transaction Limit: 300,000/-<br/>
                        <b className={"text-warning"}>Withholding Tax (WHT): </b>
                        As provided for by the Income Tax Act, Cap 472, all gaming companies are required to withhold winnings at a rate of 20%.
                        This is the Withholding Tax. In compliance with the law, we will deduct and remit to KRA 20% of all winnings.
                    </Typography>
                </Stack>
            </form>
        );
    }
}

export default Withdrawal;