import React from 'react'
import storage from "../../../Storage";

export default class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            user_id: storage.getKey('user_id') ? storage.getKey('user_id') : null,
        };
    }

    componentWillMount() {

    }

    render() {

        return (
            <>
                [To do]
            </>
        );
    }
}