import Cookies from "js-cookie";
import storage from "./Storage";
import {encode, randomString} from "./Helper";
import socket from "./Socket";
import C from "./Constant";

export function doLogin(data) {
    Cookies.remove("session");

    storage.setKey('token', data.token);

    Cookies.set("session", data.token, {expires: 14}); //Dan 14
    Cookies.set("auth", true, {expires: 14}); //Dan -- from Pages/index
    Cookies.set("uid", data.uid, {expires: 14}); //Dan -- from Pages/index

    //FAKES
    storage.setKey('user_token', randomString(50));
    storage.setKey('jwt_token', randomString(50));
    storage.setKey('secret_user', randomString(44));
    storage.setKey('secret_realtime', randomString(50));
    storage.setKey('security_key', randomString(10));
    storage.setKey('token_key', randomString(64));
    storage.setKey('secret_token', randomString(64));

    //REALS
    storage.setKey('name', data.name);
    storage.setKey('avatar', data.avatar);
    storage.setKey('email', data.email);
    storage.setKey('credit', data.credit);
    storage.setKey('room', data.room);
    storage.setKey('friends', data.friends);
    storage.setKey('user_id', data.user_id);

    socket.emit(C.ONLINE, encode({
        jwt_token: storage.getKey('jwt_token'),
        user_token: storage.getKey('user_token'),
        security_key: storage.getKey('security_key'), //fake
        secret_user: storage.getKey('secret_user'), //fake
        secret_realtime: storage.getKey('secret_realtime'), //fake
        client_system: storage.getKey('client_system'), //fake
        token_key: storage.getKey('token_key'), //fake
        secret_token: storage.getKey('secret_token'), //fake
        token: data.token, // REAL
        user_id: data.user_id,
        identifier: 'from login page',
    }));
}