import React from 'react'
import {BORDER_RADIUS, LOGO_MOBILE} from "../../../../Helper";
import {Link, NavLink} from "react-router-dom";
import {Divider, Grid} from "@mui/material";
import Button from "@mui/material/Button";

class Header extends React.Component {

    render() {
        return (
            <Grid className={'mb-2 bg-header-custom'} container spacing={3}>
                <Grid item xs={2}>
                    <NavLink style={{marginRight: '0.16rem'}} className={"navbar-brand"} to={"/"}>
                        <img src={LOGO_MOBILE} style={{height: '40px'}}/>
                    </NavLink>
                </Grid>
                <Grid item xs>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <div style={{display: 'inline-flex', paddingRight: '6px'}}>
                            <Link className={'text-white font-weight-bold'} style={{textDecoration: 'none'}} to={'/signup'}>
                                <Button style={{backgroundColor: 'white'}} variant="text" color="info" sx={{py: 0, color: 'red', borderRadius: '3px'}} size={'medium'}>SIGN UP</Button>
                            </Link>
                            <Divider className={"m-2"} color={"white"} orientation="vertical" flexItem/>
                            <Link className={'text-white font-weight-bold'} style={{textDecoration: 'none'}} to={'/login'}>
                                <Button style={{border: '0.05em solid white'}} variant="text" color="info" sx={{py: 0, color: 'white', borderRadius: '3px'}} size={'medium'}>LOGIN</Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Header;