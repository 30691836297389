import React from "react";
import {Row, Col} from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {decode, encode, wait, sendNotification} from "../../../../Helper";
import {Tooltip} from "react-tooltip";
import C from "../../../../Constant";

class Parent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            firstUserName: storage.getKey('name'),
            firstPhone: (storage.getKey('phone') !== 'false') ? storage.getKey('phone') : '',
            token: storage.getKey('token'),
            phone: (storage.getKey('phone') !== 'false') ? storage.getKey('phone') : '',
            username: storage.getKey('name'),
            loadingPhone: false,
            loadingUsername: false,
            loadingPrivacy: false,
            loading2Fa: false,
            checkbox: false,
            checkbox2: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        socket.on(C.EDIT_ACCOUNT, data => this.updateProfile(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateProfile(data) {
        if (this._isMounted) {
            this.setState({loadingUsername: false, loadingPhone: false});
            if (data.status) {
                storage.setKey('name', this.state.username);
                storage.setKey('phone', this.state.phone);
                sendNotification('Your Account Username was Updated!', 'success', 'top-center');
                window.location.reload();
            } else {
                return sendNotification(data.error, 'danger', 'bottom-center');
            }
        }
    }

    save(input) {
        if (this._isMounted) {
            let {token, phone, username, firstUserName, firstPhone} = this.state;

            if (input === 'username') {
                return sendNotification('Username change disabled!', 'info', 'top-center'); //temp

                if (username === firstUserName) {
                    return sendNotification('Please Enter New Username!', 'info', 'top-center');
                }

                if (!username) {
                    document.getElementById('username').classList.add('border-danger');
                    return;
                }

                if (username.trim() === "") {
                    document.getElementById('username').classList.add('border-danger');
                    return;
                }

                this.setState({loadingUsername: true});

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        token: token,
                        username: username
                    }));
                })
            }
        }
    }

    handleCheckBox = (e) => {
        this.setState({checkbox: true, loadingPrivacy: true});
        wait(1200).then(() => {
            this.setState({loadingPrivacy: false, checkbox: false});
            return sendNotification('This feature can\'t be active in your account!', 'info', 'top-center');
        })
    }

    render() {
        return (
            <>
                <Tooltip/>
                <Row>
                    <Col sm={6}>
                        Phone Number
                        <p className={'text-success'}>{this.state.phone}</p>

                        <hr style={{border: '1px solid whitesmoke'}}/>

                        <label htmlFor="username">Username</label>
                        <div className="input-group">
                            <input disabled
                                type="username"
                                id="username"
                                autoComplete={'off'}
                                className="form-control"
                                value={this.state.username}
                                required={true}
                                onChange={e => this.setState({username: e.target.value})}
                            />
                            <div className="input-group-append">
                                <button style={{width: '100%'}} onClick={e => this.save('username')} type="button"
                                        className="btn btn-secondary no-shadow btn-clipboard" data-tip="Save">
                                    {this.state.loadingUsername ?
                                        <div className="spinner-border spinner-border-sm" role="status"/>
                                        : <i className="mdi mdi-content-save-settings"/>
                                    }
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col style={{borderLeft: '1px solid whitesmoke'}} sm={6}>
                        <span className={'text-warning'}>
                            Your can change your Username and click save.<br/><br/><br/>
                            You can also change your Password from the button below.
                        </span>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Parent;