import React, {Component} from 'react'
import {Modal} from "react-bootstrap";
import storage from "../../../../Storage";
import {sendNotification, Event, isMobile} from "../../../../Helper";
import Wallet from "./Wallet";
import Logout from "../../../Pages/Auth/Logout";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Button from "@mui/material/Button";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            initState: 0,
            phone: storage.getKey('phone'),
            name: storage.getKey('name'),
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('other_coins', () => {
            this.setState({show: false});
        })
        Event.on('update_payment', () => {
            this.setState({show: false});
        })
        Event.on('show_deposit', () => {
            this.setState({initState: 0});
            this.handleShow();
        })
        Event.on('show_verify_mpesa', () => {
            this.setState({initState: 1});
            this.handleShow();
        })
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    handleShow() {
        if (storage.getKey('token') === null) {
            return sendNotification('Please Login to use this option.', 'warning', 'top-right');
        }

        this.setState({show: true, effect: 'pulse'});
    }

    render() {
        return (
            <>
                <Button onClick={this.handleShow} className={'p-0'} size={'small'} variant={'outlined'} name={'wallet'} color={'success'}><span
                            style={{fontSize: '0.6rem', alignItems: 'center', display: 'inline-flex', fontWeight: 'bold'}}><RequestQuoteIcon/>&nbsp;Wallet&nbsp;</span></Button>

                <Modal size="md" backdrop={'static'} centered={true} scrollable={false} show={this.state.show} onHide={this.handleClose} aria-labelledby="wallet-md-modal" className={"animated " + this.state.effect}>
                    <Modal.Header>
                        Account:&nbsp;<dt style={{textTransform: 'none', color: '#E43B1E'}}>{this.state.name}&nbsp;[{this.state.phone}]</dt>
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={'p-0 wallet-modal'}>
                        <Wallet initState={this.state.initState}/><br/>
                        <Logout/>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Main;